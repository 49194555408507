import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://b64c0d4ab8a80ff1ca997269f08eae3f@o1214795.ingest.us.sentry.io/4507271375028224",
  debug: false,
  environment: "production",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});